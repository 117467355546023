import { EventEmitter, Injectable, OnInit, Output } from '@angular/core';
import { CallbackType, ChoiceCallback, Config, ConfigOptions, FRAuth, FRCallback, FRDevice, FRStep, FRUser, NameCallback, PasswordCallback, StepType, TextInputCallback, TextOutputCallback, ValidatedCreatePasswordCallback } from '@forgerock/javascript-sdk';

import { HttpClient, HttpParams, HttpStatusCode } from '@angular/common/http';
import { FRConfig, FRResponseError, FRStepError, FRStepInput, Stage,  UserModel } from './forge-rock.interface';
import { BehaviorSubject, catchError, map, of, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserService } from './user.service';
import { ForgeRockStage } from './forge-rock-stage';
import { ValidationErrors } from '@angular/forms';
import { ERRORS } from '../components/global-error/global-error.component';

@Injectable({
  providedIn: 'root'
})
export class ForgeRockService  {
  private frConfig !: FRConfig;
  private previousStep : FRStep | undefined = undefined;
  private previousStage : Stage | undefined;
  private loginRedirect !: string;

  @Output() OnLoginFailure : BehaviorSubject<Object> = new BehaviorSubject({});
  @Output() OnLoginSuccess : BehaviorSubject<Object> = new BehaviorSubject({});
  @Output() onFRResponseError: BehaviorSubject<Object> = new BehaviorSubject({});
  @Output() onEmailVerificationCodeSent: BehaviorSubject<Object> = new BehaviorSubject({});
  @Output() OnRedirecting : BehaviorSubject<Object> = new BehaviorSubject({});

  constructor(private httpClient: HttpClient,
              private userService: UserService
  ) {
  }

  public setConfig(options:ConfigOptions) {
    this.clearPrevJournery();
    let configOptions: ConfigOptions = {
      clientId: options.clientId ? options.clientId : this.frConfig.clientId,
      redirectUri: options.redirectUri ?  options.redirectUri : `${window.location.origin}/success.html`,
      scope: options.scope ? options.scope : this.frConfig.scope,
      serverConfig: {
        baseUrl: this.frConfig.amUrl,
        timeout: parseInt(this.frConfig.timeout), // 90000 or less
      },
      realmPath: this.frConfig.realmPath,
      tree: options.tree ?  options.tree : this.findTreeName('login'),
      logLevel: this.frConfig.loglevel ? this.frConfig.loglevel : 'debug'
    };
    Config.set(configOptions);
  }

  public verifyEmailCode(data: any,userType:string) {
    let url = this.frConfig.serviceLayerUrl + environment.common.fr.endpoints.verifyEmail;
    return this.httpClient.post<any>(url,data,{observe: 'response'}).pipe(
      catchError((err)=>{
        return of(false)
      }),
      map((resp:any) => {
        const loginRedirect = this.frConfig.redirects?.find(r => r.userType == userType && r.name == 'login')?.value;
        this.redirect(loginRedirect,environment.common.fr.redirectDelay);
        return resp?.status ? true : false;
      })
    );
  }

  public clearPrevJournery() {
    this.previousStage = undefined;
    this.previousStep  = undefined;
  }

  public hasSessionToken() {
    let step: any = this.previousStep;
    if (step?.type == 'LoginSuccess') {
        return step.getSessionToken();
    }
  }

  private shouldRestartJournery() {
    let stepType:any = this.previousStep?.type;
    if ( stepType && stepType == StepType.LoginFailure && this.previousStage == Stage.LOGIN_ERROR) {
      this.clearPrevJournery();
      return true;
    }
    return false;
  }

  public async nextStep(stepInput ?: FRStepInput): Promise<Stage> {
    let restartJournery = this.shouldRestartJournery();
    if (this.previousStep && stepInput) {
        await this.setStepProperties(this.previousStep, stepInput);
    }
    try {
      let newStep: any =  await FRAuth.next(this.previousStep);
      let stage  = this.handleStep(newStep);
      if (stage == Stage.AUTO_SUBMIT || restartJournery) {
         stage = await this.nextStep(stepInput);
      }
      return stage;
    }catch(err) {
      if (err instanceof FRResponseError) {
        this.onFRResponseError.next(err);
      }else{
        console.log(err);
      }
      throw err;
    }
  };

  public loadConfig(type ?:string) {
    if (!this.frConfig) {
      let _type = type ? type : "";
      return this.httpClient.get<FRConfig>('config',{params: {type:_type}}).pipe(
        map(resp => {
          this.frConfig = resp;
          this.loginRedirect = this.frConfig.redirects?.find(r => r.userType == type && r.name == 'login')?.value;
          return this.frConfig;
        }));
    }
    return of(this.frConfig)
  }

  public findTreeName(action:string) {
    return this.frConfig.trees.find(t => t.name == action)?.value
  }

  public getChoices(stage:Stage) {
    let choices = [];
    if ([Stage.OTP_METHOD,Stage.OTP_RESEND].includes(stage)) {
       const callback:any = this.previousStep?.getCallbackOfType(CallbackType.ChoiceCallback);
       choices = callback?.getChoices();
    }
    return choices;
  }

  private setStepProperties(step: any, stepInput ?: FRStepInput) {
    let promise: Promise<any> =  Promise.resolve('Done');
    let credentials = stepInput as UserModel;
    step.callbacks.forEach((callback:any,index:number) =>{
       switch (callback.getType()) {
          case CallbackType.ValidatedCreateUsernameCallback:
                callback.setValidateOnly(credentials.validateUsername);
                callback.setName(credentials.username);break;
          case CallbackType.NameCallback:
               let val = this.getValueForNameCallback(callback,credentials);
               callback.setName(val); break;
          case CallbackType.ValidatedCreatePasswordCallback:
              callback.setValidateOnly(credentials.validatePassword);
              callback.setPassword(credentials.password); break;
          case CallbackType.PasswordCallback:
               callback.setPassword(credentials.password); break;
          case CallbackType.ChoiceCallback:
               if (credentials.otpChoice?.choiceIndex) {
                callback.setChoiceIndex(credentials.otpChoice.choiceIndex);
               }
               break;
          case CallbackType.TextInputCallback:
              callback.setInput(credentials.unverifiedEmail || credentials.dnSecurityEmail ); break;
          case CallbackType.StringAttributeInputCallback:
              callback.setValue(credentials.unverifiedEmail); break;
          case CallbackType.DeviceProfileCallback:
               promise = this.setDeviceProfile(callback).then((profile) => {
                callback.setProfile(profile);
               });
               break;
          case CallbackType.TermsAndConditionsCallback:
                callback.setText(credentials.touAccepted); break;
       }

    })
    return promise;
  }

  private getValueForNameCallback(callback:any, credentials:UserModel) {
    let value = undefined;
    let stage = ForgeRockStage.findStageByPrompt(callback, environment.common.fr.textPrompts);
    switch (stage) {
      case Stage.TOU: value =  credentials.touAccepted ? this.frConfig.callbackProperties?.tou.version : undefined; break;
      case Stage.APPNAME:  value =  credentials.touAccepted ? this.frConfig.callbackProperties?.tou.appname: undefined;break;
      case Stage.CORPCODE: value = credentials.touAccepted ? this.frConfig.callbackProperties?.tou.corpcode: undefined;break;
      case Stage.TOKEN: value = this.userService.userInfo.token;break;
      case Stage.USER_IDENTITY: value = credentials.identityAnswer; break;
      case Stage.VERIFIED_EMAIL: value = credentials.unverifiedEmail || credentials.dnSecurityEmail;break;
      case Stage.USERNAME: value = credentials.username;break;
      default:  value = undefined;
    }
    return value;
  }

  private async setDeviceProfile(callback:any) {
    const message = callback.getMessage();
    const isLocationRequired = callback.isLocationRequired();
    const isMetadataRequired = callback.isMetadataRequired();
    const device = new FRDevice();
    const profile = await device.getProfile({ location: isLocationRequired, metadata: isMetadataRequired });
    return profile;
  }

  private isPasswordReset() {
    return Config.get().tree == this.findTreeName('resetPassword');
  }

  private isPasswordChange() {
    return Config.get().tree == this.findTreeName('changePassword');
  }

  private isEmailChange() {
    return Config.get().tree == this.findTreeName('changeEmail');
  }

  private isUsernameRecovery() {
    return Config.get().tree == this.findTreeName('recoverUsername');
  }

  private isRegistration() {
    return Config.get().tree == this.findTreeName('registration');
  }

  private isLogin() {
    return Config.get().tree == this.findTreeName('login');
  }

  async logout(redirectUrl ?: string) {
    await FRUser.logout();
    if (redirectUrl) {
     this.redirect(redirectUrl);
    }
  }

  public redirect(gotoUrl: string,delay:number=0) {
    setTimeout(() => {
        this.OnRedirecting.next({redirecting:true})
        window.location.href =  gotoUrl;
    }, delay);
  }

  private redirectTo(gotoUrl: string):void {
    if (this.previousStage == Stage.EMAIL_SENT || this.isRegistration()) return;
    let redirectTo = gotoUrl;
    if (this.isLogin()) {
      redirectTo = gotoUrl.includes("redirect_uri") ? gotoUrl : `${window.location.origin}/success.html`;
    }
    let delay = this.isRegistration() || this.isPasswordReset() || this.isUsernameRecovery() || this.isPasswordChange() ? environment.common.fr.redirectDelay : 0;
    this.redirect(redirectTo,delay);
  }

  private handleStep(step: any):Stage {
    switch (step.type) {
      case 'LoginSuccess': {
        if (this.isLogin()) {
          this.OnLoginSuccess.next({'loginSuccess':true});
        }
        let gotoUrl = Config.get().redirectUri;
        if (gotoUrl) {
          if (this.isPasswordReset()) this.logout();
          this.previousStep = step;
          this.redirectTo(gotoUrl);
        }
        return Stage.LOGIN;
      }
      case 'LoginFailure': {
        if (this.previousStage == Stage.EMAIL_SENT) {
          this.previousStep = step;
          this.previousStage = Stage.EMAIL_SENT_REDIRECT;
          if (this.isUsernameRecovery()) {
            this.redirectTo(this.loginRedirect);
          }
          return Stage.EMAIL_SENT_REDIRECT
        }else{
          this.raiseLoginErrorEvent(undefined,step);
        }
        let newStage = Stage.LOGIN_ERROR;
        if (this.previousStage && !([Stage.USER_IDENTITY].includes(this.previousStage))) {
          this.previousStep = step;
          this.previousStage = newStage;
        }
        if (!this.previousStage) {
          throw new FRResponseError(ERRORS.UNEXPECTED_STAGE.toString());
        }
        return newStage;
      }
      default: {
        let stage = ForgeRockStage.getStage(step);
        this.raiseLoginErrorEvent(stage,step);
        if (this.isRegistration() && this.previousStage == Stage.PASSWORD && stage == Stage.VERIFIED_EMAIL) {
          throw new FRResponseError(ERRORS.UNEXPECTED_STAGE.toString()); // throw global error
        }
        if (this.isEmailChange() && stage == Stage.LOGIN) {
          this.clearPrevJournery(); //Only logged in users are allowed to change email
          this.redirectTo(this.loginRedirect);
        }
        if (stage && stage != Stage.LOGIN_ERROR) {
          this.hasEmailChangeMandate(stage,step);
          this.previousStep = step;
          this.previousStage = stage;
        }
        return stage;
      }
    }
  };

  public  getCurrentPolicyViolations() {
    let step = this.previousStep;
    let errors:ValidationErrors | null= null;
    if (step && (![StepType.LoginFailure,StepType.LoginSuccess].includes(step?.type))) {
        [CallbackType.ValidatedCreatePasswordCallback, CallbackType.ValidatedCreateUsernameCallback].forEach((c)=>{
          if (step?.getCallbacksOfType(c).length) {
              const callback: any = step?.getCallbackOfType(c);
              callback.getFailedPolicies().forEach((failed:any)=>{
                let errorId = 'failedPolicy';
                if (failed.policyRequirement?.toLowerCase() == 'unique') {
                  errorId = 'uniqueValue';
                }else{
                  errorId = environment.common.errorMessages[failed.policyRequirement?.toLowerCase() as keyof {}] ? failed.policyRequirement.toLowerCase() : errorId;
                }
                errors ={[errorId]:true}
                console.error('Validations failed: ' + failed.policyRequirement);
              })
          }
        });
    }
    return errors;
  }

  private hasEmailChangeMandate(stage:Stage,step:FRStep) {
    if (stage && [Stage.VERIFIED_EMAIL].includes(stage)) {
      if (step?.getCallbacksOfType(CallbackType.TextOutputCallback).length) {
        const callback: TextOutputCallback = step?.getCallbackOfType(CallbackType.TextOutputCallback);
        const message = callback.getMessage();
        let errorId = environment.common.fr.knownMessages.find( m => m.message.toLowerCase() == message?.toLowerCase())?.id;
        if (errorId) this.userService.addDuplicateEmail() ;
      }
    }
  }

  //Custom Login Failure Messages for error handler. Error messages can be in TextOutputCallback or LoginFailure step or ChoiceCallback (OTP)
  private raiseLoginErrorEvent(currentStage:any,step:any) {
    let messages = environment.common.fr.knownMessages;
    let message = {} as FRStepError;

    if (step.type != 'LoginFailure') {
        if (this.previousStage == Stage.OTP && currentStage == Stage.OTP_RESEND) {
            message.message= messages.find((m)=>m.id == 'invalidOtp')?.message;
            message.code = '401';
        }
        if (this.isPasswordReset() && this.previousStage == Stage.PASSWORD && currentStage == Stage.AUTO_SUBMIT) {
          message.message= messages.find((m)=>m.id == 'passwordPolicy')?.message;
          message.code = '401';
        }
        if (currentStage == Stage.LOGIN_ERROR || currentStage == Stage.USER_IDENTITY) {
            message.message= messages.find((m)=>m.id == 'invalidCredential')?.message;
            message.code = '401';
        }
        if (this.isRegistration() && this.previousStage == Stage.PASSWORD && currentStage == Stage.VERIFIED_EMAIL  ) {
            message.message = "createUser"; //BIDM couldn't create user
            message.code = '401';
        }
    }

    if (step.type == 'LoginFailure') {
      if (this.previousStage && this.previousStep?.getCallbacksOfType(CallbackType.TextOutputCallback)?.length) {
          let textOutputCallback : TextOutputCallback = this.previousStep?.getCallbackOfType(CallbackType.TextOutputCallback);
          let index = ForgeRockStage.indexOfPrompt(textOutputCallback,environment.common.fr.messages);
          if (index > -1 && environment.common.fr.messages[index].stage == Stage[this.previousStage]) {
            message.message = environment.common.fr.messages[index].dnMessage;
            message.code = '401';
          }
      }else if (this.isPasswordReset() && this.previousStage != Stage.OTP) {
          let msg = this.previousStage == Stage.USERNAME ? 'invalidUsername' : 'passwordPolicy';
          message.message= messages.find((m)=>m.id == msg)?.message;
          message.code = '401';
      }else{
          message = {code:step.getCode(), message: (step.getMessage() || step.getReason())};
      }
    }

    if (Object.keys(message).length) {
      this.OnLoginFailure.next(message);
    }
  }


}
