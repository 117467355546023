export class FRResponseError extends Error {} 

export interface FRStepError {
    code: string;
    message: string | undefined;
}
 
export enum EmailConfirmStatus {
    VERIFICATION_CODE_SENT = <any>'sentVerificationCode',
    REGISTRATION_SUCCESS = <any>'registered',
    EMAIL_VERIFIED_SUCCESS= <any>'emailVerifiedSuccess',
    EMAIL_VERIFIED_FAILED= <any>'emailVerifiedFailed',
    USERNAME_EMAIL_SENT= <any>'sentUsernameEmail'
}

export enum Stage  {
    LOGIN = <any>'UsernamePassword',
    USER_CREATION = <any>'UsernamePasswordEmail',
    USER_IDENTITY = <any>'UserIdentity',
    OTP_METHOD = <any>'OtpMethodSelection',
    TOU = <any>'TermsAndCondition',
    TOKEN = <any>'token',
    APPNAME = <any>'Appname',
    CORPCODE = <any>'corpCode',
    OTP = <any>'OnetimePassword',
    OTP_RESEND = <any>'ResendCode',
    AUTO_SUBMIT = <any>'AutoSubmits',
    LOGIN_ERROR = <any>'LoginError',
    VERIFIED_EMAIL = <any>'verifiedEmail',
    UNVERIFIED_EMAIL = <any>'unverifiedEmail',
    USERNAME = <any>'username',
    PASSWORD = <any>'password',
    CURRENT_PASSWORD = <any>'currentPassword',
    LOGIN_REDIRECT = <any>'redirectToLogin',
    EMAIL_SENT = <any>'emailVerificationCodeSent',
    EMAIL_SENT_REDIRECT = <any>'emailSentRedirect',
    FATAL = <any>'Fatal'
}

export interface NameValuePair {
    name : string;
    value : any;
} 

export interface RedirectUrl extends NameValuePair {
    userType : string
}

export interface tou {
    appname : string;
    version : string;
    corpcode: string;
}

export interface CallbackProperties {
    tou : tou;
}
export interface FRConfig {
    serviceLayerUrl : string;
    amUrl : string;
    realmPath: string;
    scope: string;
    timeout: string;
    clientId: string;
    trees: NameValuePair[];
    loglevel: any;
    redirects ?: RedirectUrl[]
    callbackProperties ?: CallbackProperties;
}

export interface FRStepInput {
}

export interface UserCredential extends FRStepInput{
    username ?: string,
    password ?: string
}
export interface UserModel extends UserCredential {
    firstname : string;
    lastname  : string;
    phoneNumber : string;
    dnSecurityEmail: string; //Original email 
    unverifiedEmail : string;
    touAccepted : boolean;
    identityVerified: boolean;
    identityAnswer ?: string;
    otpChoice : any;
    registered : boolean;
    userType ?: string;
    emailConfirmed : boolean;
    validateUsername : boolean;
    validatePassword : boolean;
}

export interface UserAuthResponse {
    tokenId: string;
    successUrl: string;
    realm: string;
}

export interface UserInfo {
    firstname : string;
    lastname  : string;
    dnSecEmail : string;
    phoneNumber : string;
    tokenId : string;
    userType ?: string;
    token ?: string;
}

export interface NavStep {
    stage: Stage
    navText: string
}

export interface TOU {
    appname : string;
    version : string;
    corpCode: string;
}