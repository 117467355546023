import { Injectable, Output } from '@angular/core';
import { CallbackType, ChoiceCallback, Config, FRCallback, FRStep, PasswordCallback, TextInputCallback, TextOutputCallback } from '@forgerock/javascript-sdk';
import { environment } from '../../../environments/environment';
import {  FRResponseError, FRStepInput, Stage } from './forge-rock.interface';
import { ERRORS } from '../components/global-error/global-error.component';


export class ForgeRockStage {

  public static getStage(step : FRStep)  {
    let callbacks = new Map<CallbackType,FRCallback[]>();
    Object.keys(CallbackType).forEach((callbackType:any)=>{
        callbacks.set(callbackType,step.getCallbacksOfType(callbackType))
    });

    let stage = undefined;

    let isPasswordCallback = callbacks.get(CallbackType.PasswordCallback)?.length || callbacks.get(CallbackType.ValidatedCreatePasswordCallback)?.length;
    let isUsernameCallback = callbacks.get(CallbackType.ValidatedCreateUsernameCallback)?.length;
    //PasswordCallback
    if (isPasswordCallback) {
      if (callbacks.get(CallbackType.NameCallback)?.length || isUsernameCallback) {
         let knownStages = isUsernameCallback ? [Stage.USERNAME,Stage.PASSWORD] : [Stage.PASSWORD];
         stage = this.stageFromNameCallbacks(callbacks,knownStages);
         if (stage == Stage.USER_CREATION) {
           let textOutputCallback : TextOutputCallback = step.getCallbackOfType(CallbackType.TextOutputCallback);
           if (!(textOutputCallback.getMessage()?.toLowerCase() == environment.common.fr.knownMessages.find(m=>m.id == 'uniqueEmail')?.message?.toLowerCase())) {
             stage = Stage.LOGIN_REDIRECT;
           }
         }
         if (!stage) {
            stage=Stage.LOGIN;
         }
      }else{
        if (callbacks.get(CallbackType.ValidatedCreatePasswordCallback)?.length) {
          stage = Stage.PASSWORD;
        }else{
          const passwordCallback:PasswordCallback = step.getCallbackOfType(CallbackType.PasswordCallback)
          stage = ForgeRockStage.findStageByPrompt(passwordCallback, environment.common.fr.passwordPrompts);
        }
      }
    }

    if (!isPasswordCallback && callbacks.get(CallbackType.NameCallback)?.length) {
       stage = ForgeRockStage.stageFromNameCallbacks(callbacks);
    }

    if (!isPasswordCallback && isUsernameCallback) {
      stage = Stage.USERNAME;
    }

    if (!isPasswordCallback && callbacks.get(CallbackType.TextInputCallback)?.length) {
      const textInputCallback:TextInputCallback = step.getCallbackOfType(CallbackType.TextInputCallback)
      stage = ForgeRockStage.findStageByPrompt(textInputCallback, environment.common.fr.textPrompts);
    }

    //Choice callbacks
    if (callbacks.get(CallbackType.ChoiceCallback)?.length) {
      let choiceCallback:ChoiceCallback = step.getCallbackOfType(CallbackType.ChoiceCallback);
      stage = ForgeRockStage.findStageByPrompt(choiceCallback,environment.common.fr.choicePrompts)
    }

    //DeviceProfile and other auto submitted callbacks
    if (callbacks.get(CallbackType.DeviceProfileCallback)?.length) {
      stage = Stage.AUTO_SUBMIT;
    }

    if (callbacks.get(CallbackType.TextOutputCallback)?.length && callbacks.get(CallbackType.ConfirmationCallback)?.length) {
       let textOutputCallback : TextOutputCallback = step.getCallbackOfType(CallbackType.TextOutputCallback);
       stage = ForgeRockStage.findStageByPrompt(textOutputCallback,environment.common.fr.messages);
    }

    if (callbacks.get(CallbackType.TermsAndConditionsCallback)?.length) {
      stage = Stage.TOU;
    }

    if (callbacks.get(CallbackType.StringAttributeInputCallback)?.length) {
      stage = Stage.UNVERIFIED_EMAIL //In future, may need to check for prompt
    }

    if (stage) {
      return stage;
    }
    throw new FRResponseError(ERRORS.INVALID_STAGE.toString());
  };

  public static findStageByPrompt(callback:any, prompts: any[]) {
    let promptNdx = ForgeRockStage.indexOfPrompt(callback,prompts);
    if (promptNdx > -1) {
      let stageVal = prompts[promptNdx].stage;
       return Stage[stageVal as keyof typeof Stage];
    }
    return undefined;
  }

  public static indexOfPrompt(callback:any, prompts: any[]) {
    let prompt = callback instanceof TextOutputCallback ? callback.getMessage()?.toLowerCase() : callback.getPrompt()?.toLowerCase();
    return prompts.findIndex((c) =>  prompt.includes(c.prompt.toLowerCase()));
  }

  private static isRegistration(step: FRStep) {
     return step.getStage() && step.getStage()?.toLocaleLowerCase().includes(environment.common.fr.journeys.registration.toLocaleLowerCase());
  }

  private static stageFromNameCallbacks(callbacks:Map<CallbackType,FRCallback[]>, alsoIncludesStages ?: Stage[] ) {
    let stages: any[] =  alsoIncludesStages ? alsoIncludesStages : [];
    let nameCallbacks = callbacks.get(CallbackType.NameCallback);
    nameCallbacks?.forEach((nameCallback)=>{
      let stg = ForgeRockStage.findStageByPrompt(nameCallback, environment.common.fr.textPrompts);
      if (stg) {
        stages.push(stg);
      }
    });
    
    if (stages.length > 0) {
       if ([Stage.USERNAME,Stage.PASSWORD,Stage.VERIFIED_EMAIL].filter(s => stages.includes(s)).length == 3) {
          return Stage.USER_CREATION;
       }
       if ([Stage.USERNAME,Stage.PASSWORD].filter(s => stages.includes(s)).length == 2) {
          return Stage.LOGIN;
      }
      return stages.includes(Stage.TOU) ? Stage.TOU : stages[0]; 
    }
    return undefined;
  }
}
